import React from 'react';
import { graphql } from 'gatsby';

import {
	Layout,
	Hero,
	List,
	Container,
	NumberedContent,
	Spacer,
} from '../components';

const NicotinePouchesPage = ({
	pageContext,
	data: {
		wordpressPage: { acf: translations },
	},
}) => (
	<Layout
		title={translations.seo_title}
		description={translations.seo_description}
		image={translations.seo_cover_image}
		pageContext={pageContext}
	>
		<Hero
			image={translations.nicotine_pouches_background_image}
			title={translations.nicotine_pouches_title}
			text={translations.nicotine_pouches_descriptions}
		/>
		<Spacer mobile={50} />
		<Container left={236} right={136}>
			<NumberedContent
				indent
				number="1"
				title={translations.nicotine_pouches_why_usage_title}
			>
				<List>
					{translations.nicotine_pouches_why_usage_list.map(
						(item, index) => (
							<li className="f-large" key={index}>
								{item.item}
							</li>
						)
					)}
				</List>
			</NumberedContent>
		</Container>
		<Spacer mobile={50} />
		<Container left={236}>
			<NumberedContent
				indent
				number="2"
				title={translations.nicotine_pouches_how_usage_title}
			>
				<img
					src={translations.nicotine_pouches_how_usage_image}
					alt={translations.nicotine_pouches_how_usage_title}
					loading="lazy"
				/>
			</NumberedContent>
		</Container>
		<Spacer mobile={50} />
		<Container left={236} right={136}>
			<NumberedContent
				indent
				number="3"
				title={translations.nicotine_pouches_if_usage_title}
			>
				{translations.nicotine_pouches_if_usage_descriptions.map(
					(description, index) => (
						<p key={index} className="f-large color-black-light">
							{description.description}
						</p>
					)
				)}
			</NumberedContent>
		</Container>
		<Spacer mobile={70} desktop={100} />
	</Layout>
);

export default NicotinePouchesPage;

export const query = graphql`
	query($id: Int, $lang: String!) {
		wordpressPage(
			wordpress_id: { eq: $id }
			wpml_current_locale: { eq: $lang }
		) {
			wpml_current_locale
			acf {
				seo_title
				seo_description
				seo_cover_image
				nicotine_pouches_title
				nicotine_pouches_descriptions {
					description
				}
				nicotine_pouches_background_image
				nicotine_pouches_why_usage_title
				nicotine_pouches_why_usage_list {
					item
				}
				nicotine_pouches_how_usage_title
				nicotine_pouches_how_usage_image
				nicotine_pouches_if_usage_title
				nicotine_pouches_if_usage_descriptions {
					description
				}
			}
		}
	}
`;
